import React, { useState, useRef } from 'react';
import { parrot } from './parrot';
import { Button, Col, Container, Form, Overlay, Row, Tooltip } from 'react-bootstrap';
import copy from 'clipboard-copy';

const sets = {
  emoji: ['😈', '👻'],
  parrots: [':parrot_wave_3:', ':parrot_wave_6:'],
};

function App() {
  const selectedSet = 'emoji'; // TODO: set picker
  const [defOff, defOn] = sets[selectedSet];
  const [on, setOn] = useState(defOn);
  const [off, setOff] = useState(defOff);
  const [val, setVal] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const target = useRef(null);

  const setEmotes = (on, off) => {
    setOn(on);
    setOff(off);
  };

  const flipEmotes = () => setEmotes(off, on);

  const parroted = parrot(off, on, val);

  return (
    <Container>
      <Row>
        <Col sm={12}>
          <h1>Parrot Words</h1>
          <Form>
            <Form.Group>
              <Form.Label>Off Emote</Form.Label>
              <Form.Control
                placeholder="Off Emote"
                value={off}
                onChange={e => {
                  setShowTooltip(false);
                  setOff(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>On Emote</Form.Label>
              <Form.Control
                placeholder="On Emote"
                value={on}
                onChange={e => {
                  setShowTooltip(false);
                  setOn(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Button
                variant="info"
                onClick={e => {
                  setShowTooltip(false);
                  flipEmotes();
                }}
              >
                Flip
              </Button>
            </Form.Group>

            <Form.Group>
              <Form.Label>Convert:</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                value={val}
                onChange={e => {
                  setShowTooltip(false);
                  setVal(e.target.value);
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Result:</Form.Label>
              <Form.Control
                as="textarea"
                id='value'
                rows="20"
                value={parroted}
                disabled
              />
            </Form.Group>
            <Form.Group>
              <Button
                ref={target}
                variant="primary"
                onClick={e => {
                  copy(parroted).then(() => {
                    setShowTooltip(true);
                  });
                }}
              >
                Copy to clipboard
              </Button>
              <Overlay target={target.current} show={showTooltip} placement="right">
                {props => (
                  <Tooltip id="overlay-example" {...props}>
                    Copied!
                  </Tooltip>
                )}
              </Overlay>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
